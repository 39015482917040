.root {
    overflow: auto;
    max-height: calc(100vh - 200px);
    &::-webkit-scrollbar{
        height: 8px;
    }
  .table {
    width: 100%;
    // background-color: var(--pro-secondary);
    position: sticky;
    top: 0;
    border-radius: 4px;
    td,
    th {
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      &:first-of-type{
        background-color: var(--pro-light);
        padding: 0;
        padding-right: 8px;
        width: 53px;
        position: sticky;
        left: 0;
        z-index: 3;
    }
    }
    th:first-child{
        z-index: 5;
    }
    td{
        padding: 10px 4px;
        background-color: var(--pro-secondary);
        &:nth-child(2){
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding-left: 8px;
        }
        &:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            padding-right: 8px;
        }
    }
     tr{
        border-top: 13px solid var(--pro-light);
        &:nth-child(5n+ 1) td{
            --pro-title-bg: #B53030;
        }
        &:nth-child(5n+ 2) td{
            --pro-title-bg: #00804C;
        }
        &:nth-child(5n+ 3) td{
            --pro-title-bg: #CF8900;
        }
        &:nth-child(5n+ 4) td{
            --pro-title-bg: #794BD1;
        }
        &:nth-child(5n+ 5) td{
            --pro-title-bg: #0186C0;
        }
    }
    th{
        text-align: center;
        padding: 4px;
        position: sticky;
        background-color: var(--pro-light);
        top: -7px;
        z-index: 3;
        &:nth-child(2){
            padding-left: 0;
        }
        &:nth-child(5n + 1){
            --pro-title-bg: #B53030;
        }
        &:nth-child(5n + 2){
            --pro-title-bg: #0186C0;
        }
        &:nth-child(5n + 3){
            --pro-title-bg: #794BD1;
        }
        &:nth-child(5n + 4){
            --pro-title-bg: #CF8900;
        }
        &:nth-child(5n + 5){
            --pro-title-bg: #00804C;
        }
    }
  }
}
