.aling_btn {
  transform: rotate(90deg);
}
.container_root {
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid var(--pro-outline-border);
  background-color: var(--pro-light);
  border-radius: 5px;
  align-items: center;
  button {
    white-space: nowrap;
  }
}

.color_dropdown {
  width: 100%;
  color: #ffffff;
  --bg-color: #0186c0;
  &::before {
    position: absolute;
    content: "";
    background-color: var(--bg-color);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  &.completed {
    --bg-color: #00804c;
  }
  &.pending {
    --bg-color: #0186c0;
  }
  &.in_progress {
    --bg-color: #cf8900;
  }
  &.course_lagging{
    --bg-color: #A80000;
  }
  &.take_break{
    --bg-color: #710791;
  }
  
}

.table_wrap {
  overflow: initial !important;
}

.report_root {
  max-width: 170px;
  padding-right: 17px;
  margin-right: 17px;
  border-right: 1px solid var(--pro-outline-border);
  &:first-child ~ .item {
    padding-left: 0;
  }
  &:last-child {
    border-right: 0px;
    max-width: 500px;
  }
}


.table_container{
  position: relative;
  z-index: 2;
  :global{
    .pro-table th{
      z-index: 0;
    }
  }
}

.loop_list{
   & +.loop_list{
    margin-top: 30px;
   }
}
.timeslot_canvas{
  --pro-offcanvas-width : 500px !important;
}