.search_wrap {
  padding: 1.8rem 1.6rem;
  padding-top: 0;
  max-height: 600px;
  overflow-y: auto;
  position: relative;
  .search_bar_wrap {
    position: sticky;
    padding-top: 1.8rem;
    padding-bottom: 10px;
    top: 0;
    background: var(--pro-light);
    z-index: 3;
  }
  .search_bar {
    position: relative;
    .input {
      width: 100%;
      --pro-input-padding-x: 38px;
    }
    .icon {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      align-items: center;
    }
    .search_icon {
      left: 10px;
    }
    .key_icon {
      right: 10px;
      background-color: var(--pro-light);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }
  }
}
