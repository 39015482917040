.modal_root{
    --pro-modal-width: 800px;
}
.color_dropdown {
    width: 100%;
    color: #ffffff;
    --bg-color: #0186c0;
    &::before {
        position: absolute;
        content: "";
        background-color: var(--bg-color);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
      }
}