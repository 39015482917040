.root {
  :global(.dropdown) {
    width: auto;
    button {
      background: none;
      border: none;
      &::after {
        display: none;
      }
    }
  }
}
.icon_wrap{
  max-width: 22px;
  min-width: 22px;
  color: var(--pro-dark);
  &.active{
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
      background-color: var(--pro-danger);
      border-radius: 50%;
    }
  }
}

.notification_item{
  .img_wrap{
    max-width: 53px;
    min-width: 53px;
    width: 100%;
    >div{
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .content{
    > p{
      white-space: initial;
      font-size: 14px;
    }
    span{
      font-size: 10px;
      opacity: 0.6;
    }
  }
}
.notification_wrap{
  border-bottom: 1px solid var(--pro-outline-border);
  
  + p{
    font-size: 14px;
    cursor: pointer;
  }
}
.dropdown{
  --pro-dropdown-min-width:352px;
  --pro-dropdown-link-hover-bg: transparent;
  --pro-dropdown-link-active-bg: transparent;
  --pro-dropdown-padding-x: 1rem;
  --pro-dropdown-item-padding-x: 0;
  --pro-dropdown-item-padding-y:14px;
}
.no_item_msg{
  align-items: center;
  .icon{
    width: 50px;
    height: 50px;
    background-color: var(--pro-secondary);
    align-items: center;
    >span{
      opacity: 0.7;
    }
  }
}
.btn{
  cursor: pointer;
  &:hover{
    color: var(--pro-primary);
  }
}