.profileTooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;

}

.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}

.avatar_list_container {
  position: fixed;
  max-width: 250px;
  width: 100%;
  opacity: 0;
  --box-shadow: 0, 0, 0;
  box-shadow: 1px 1px 10px 0px rgba(var(--box-shadow), 0.1);
  transform: translateY(var(--transform, 5px));
  pointer-events: none;
  z-index: 3;
  --wrapper-height: 30px;

  top: calc((var(--top) * 1px) + var(--wrapper-height));
  left: calc(var(--left) * 1px);
  &.active {
    transition: transform 0.3s, opacity 0.3s;
    opacity: 1;
    transform: translateY(0);
  }
  &.top {
    top: calc((var(--top) - var(--card-height) - 15) * 1px);
  }
}

.avatar_container {
  position: relative;
  z-index: 3;
  .img_wrap {
    max-width: 18px;
    min-width: 18px;
    flex: 1;
  }
}

[data-theme*="dark"] {
  .avatar_list_container {
    --box-shadow: 255, 255, 255;
  }
}

.no_padding {
  padding-top: 0 !important;
}

.pro_input {
  --pro-input-hover-bg: transparent;
  --pro-input-focus-shadow: transparent;
  background-color: transparent;
  color: inherit !important;
  position: unset;
  :global {
    .pro-input__placeholder {
      color: inherit;
    }
    .pro-input__input-container {
      color: inherit;
    }
    .pro-input__single-value {
      color: inherit;
    }
    .pro-input__indicators {
      display: none;
    }

    .pro-input__menu-list{
      width: 100%;
    }
  }
  &:global(.pro-input) {
    position: unset;
    :global(.pro-input__menu) {
      margin: 0;
      left: 0;
    }
  }
}

.select_container {
  align-items: center;
  min-width: 200px;
}
.chat_icon {
  font-size: 20px !important;
  opacity: 0.7;
  transition: opacity 0.25s;
  &:hover {
    opacity: 0.99;
  }
}
.note {
  position: relative;
  transition: opacity 0.25s;
  &_count {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ffffff; //do not used varibales bcoz whiet is fixed in all themes
    width: 13px;
    height: 13px;
    font-size: 10px;
    color: var(--bg-color);
    user-select: none;
  }
  &:hover {
    opacity: 0.7;
  }
}
.note_date {
  position: relative;
}
