.root{
    width: 100%;
    position: relative;
}
.heading{
    width: 100%;
    position: relative;
}

.body{
    width: 100%;
    position: relative;
}


.items{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .item{
        width: 100%;
        display: flex;
        border: 1px solid var(--pro-outline-border);
        border-radius: 5px;
        overflow: hidden;
        padding-left: 12px;
        min-height: 46px;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        .icon{
            background-image: url("data:image/svg+xml,%3Csvg width='5' height='4' viewBox='0 0 5 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='%238E96A5'/%3E%3Crect x='3' width='2' height='2' fill='%238E96A5'/%3E%3C/svg%3E%0A");
            width: 5px;
            height: 24px;
            position: absolute;
            left: 7px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        :global{
            .pro-check{
                position: absolute;
                right: 11px;
                top: 0;
                bottom: 0;
                margin: auto;

            }
            .pro-check-label{
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 24px;
                padding-right: 45px;
            }
        }
    }
}