.dashboardTable {
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .dashboardTable:hover {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
  }
  
  .avatar_list_container {
    position: fixed;
    max-width: 250px;
    width: 100%;
    opacity: 0;
    --box-shadow: 0, 0, 0;
    box-shadow: 1px 1px 10px 0px rgba(var(--box-shadow), 0.1);
    transform: translateY(var(--transform, 5px));
    pointer-events: none;
    z-index: 3;
    --wrapper-height: 30px;
  
    top: calc((var(--top) * 1px) + var(--wrapper-height));
    left: calc(var(--left) * 1px);
    &.active {
      transition: transform 0.3s, opacity 0.3s;
      opacity: 1;
      transform: translateY(0);
    }
    &.top {
      top: calc((var(--top) - var(--card-height) - 15) * 1px);
    }
  }
  
  .user_select {
    min-width: 150px;
    :global(.avatar-container) {
      position: absolute;
      top: 0;
      max-width: 100%;
    }
    :global(.avatar-container .name) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.pro-input .pro-input__menu) {
      z-index: 4;
    }
  }
  
  .table_popup {
    position: fixed;
    top: 70px;
    left: 50%;
    z-index: 2;
    // transform: translateX(-50%);
    background-color: #fff6db;
    border-radius: 3px;
    padding: 5px 10px;
    transform: scale(0.8) translateY(-10px);
    opacity: 0;
    transition: opacity 0.25s ease, transform 0.25s ease;
    pointer-events: none;
    &.visible {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  .aling_btn {
    transform: rotate(90deg);
  }
  
  // .user_select{
  //   min-width: 150px;
  //   :global(.avatar-container){
  //     position: absolute;
  //     top: 0;
  //     max-width: 100%;
  //   }
  //   :global(.avatar-container .name){
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //   }
  //   :global(.pro-input .pro-input__menu){
  //     z-index: 99;
  //   }
  // }
  .color_dropdown {
    width: 100%;
    color: #ffffff; //donot used variables bcoz same color used in all themes
    --bg-color: #0186c0;
    &::before {
      position: absolute;
      content: "";
      background-color: var(--bg-color);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
    &.enquiry {
      --bg-color: #0186c0;
    }
    &.broschre {
      --bg-color: #055070;
    }
    &.demo_arrange {
      --bg-color: #cf8900;
    }
    &.follow_up {
      --bg-color: #11a0b3;
    }
    &.price_factor {
      --bg-color: #585858;
    }
    &.lost {
      --bg-color: #a80000;
    }
    &.enrolled {
      --bg-color: #00804c;
    }
    &.no_prospective {
      --bg-color: #b94e10;
    }
  }
  // .modal_root {
  //   --pro-modal-width: 600px;
  //   :global(.modal-dialog) {
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //     width: 100%;
  //     margin: 0;
  //   }
  // }
  
  .modal_body {
    max-height: 500px;
    overflow-y: auto;
  }
  