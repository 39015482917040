.root{
    background-color: var(--pro-light);
    min-width: 200px;
    max-width: 300px;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    min-height: 103px;
    p{
        margin-bottom: 0;
    }
    * + p{
        margin-top: 5px;
    }
    > *{
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
.title{
    font-size: 15px;
}
.root_break{
    min-height: 103px;
    min-width: 200px;
}