//mail editor
.pro_editor_wrapper{
    border: 1px solid var(--pro-outline-border);
    border-radius: 4px;
    .pro_editor_toolbar{
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid var(--pro-outline-border);
      margin-bottom: 0;
      padding: 10px;
      background-color: var(--pro-editor-toolbar-bg);
      :global{
        .rdw-inline-wrapper, .rdw-list-wrapper{
            margin-bottom: 0;
          }
          .rdw-option-wrapper{
            border: 0;
            background: transparent;
            min-width: 20px;
            width: 20px;
            position: relative;
            box-shadow: none !important;
            --scale: 0.7;
            z-index: 2;
            &::before{
              position: absolute;
              border-radius: 0;
              background-color: var(--pro-secondary-active);
              border-radius: 50%;
              content: "";
              height: 25px;
              width: 25px;
              left: 50%;
              top: 50%;
              transition: transform 0.2s ease-in, opacity 0.2s ease-in;
              transform: scale(var(--scale)) translate(-50%, -50%);
              opacity: 0;
              will-change: opacity, transform;
              z-index: -1;
            }
            &:hover::before, &.rdw-option-active::before{
              opacity: 1;
              --scale:1;
            }
          }
      }
    }
    .pro_editor_main{
      padding: 10px;
      :global{
        .public-DraftStyleDefault-block{
            margin: 0;
          }
          .DraftEditor-root{
            min-height: 120px;
            max-height: 300px;
            max-height: 50vh;
            word-break: break-word;
          }
      }
    }
  }

  .dropdown{
    width: auto;
    margin-left: auto;
    display: flex;
    .dropdown_btn{
      background: none;
      border: 0;
      outline: 0;
      border-radius: 50%;
      &::before, &::after {
        display: none;
      }
    }
    .dropdown_menu{
      max-width: 275px;
      .dropdown_text{
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .item_text{
      color: var(--pro-dark);
      opacity: 0.7;
    }
  }
  [data-theme*="dark"]{
    .pro_editor_toolbar{
      :global(.rdw-option-wrapper img){
        filter: invert(1);
      }
    }
    .dropdown .dropdown_btn{
      color: var(--pro-dark);
    }
  }