.root {
  background-color: var(--pro-light);
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  transition: border-color 0.25s ease;
  border: 2px solid;
  border-color: transparent;
    &:active{
      border-color: var(--pro-focus-ring-color);
    }
    &:hover{
        border-color: var(--pro-border-color);
    }
    &.active{
        border-color: var(--pro-primary);
    }
  .tick {
    width: 14px;
    height: 14px;
    border: 1px solid var(--pro-success);
    color: var(--pro-success);
    span.material-symbols-outlined {
      font-size: 14px !important;
    }
  }
  p + .card_footer {
    margin-top: 0.43rem;
  }
  .card_footer {
    align-items: center;
    gap: 0.5rem;
  }
  .kan_badge {
    background-color: var(--pro-danger);
    border-radius: 3px;
    color: var(--pro-light);
    padding-block: 3px;
    font-size: 11px;
    gap: 7px;
  }
  .number {
    color: #718797;
  }
  .icon {
    display: flex;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    > span {
      font-size: 20px !important;
    }
  }
  .note {
    position: relative;
    transition: opacity 0.25s;
    &_count {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: var(--pro-dark);
      width: 13px;
      height: 13px;
      font-size: 10px;
      color: var(--pro-light);
      user-select: none;
    }
    &:hover {
      opacity: 0.7;
    }
    svg path{
      fill: var(--pro-dark);
    }
  }
}

.pro_avatar {
  --wac-avatar-size: 20px;
  .profile_icon{
    color: var(--pro-dark);
  }
}
