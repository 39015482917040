.root {
  .add_btn {
    cursor: pointer;
    border: 1px solid var(--pro-outline-border);
    transition: background-color 0.25s ease-in;
    &:hover{
      background-color: var(--pro-secondary);
    }
  }
  .note_card {
    border: 1px solid var(--pro-outline-border);
  }
  .card_footer {
    gap: 30px;
    border-top: 1px solid var(--pro-outline-border);
  }
  .card_top {
    p {
      margin-bottom: 0;
      > * {
        margin: 0;
      }
    }
    .date {
      color: var(--pro-input-text);
    }
    p + .date {
      margin-top: 10px;
    }
  }
  .action_btn {
    svg path{
      fill: var(--pro-dark);
    }
    // &.active {
    //   color: var(--pro-primary);
    // }
  }
}
.dropdown {
  .dropdown_btn {
    border: 0;
    background-color: transparent;
    &::before {
      display: none;
    }
  }
}
