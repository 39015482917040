@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
.container {
  background-color: var(--pro-secondary);
  border-radius: 5px;
  padding: 1rem 10px 4px;
  margin: 15px 0;
  position: relative;
}

.corner_button{
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
  width: 12px;
  height: 12px;
  padding: 0;
  --pro-btn-close-opacity: 0.4;
  z-index: 2;
}
.wrapper{
  position: relative;
  background-color: var(--pro-secondary);
  border-radius: 4px;
  padding: 5px 10px;
  padding-top: 15px;
  & + .wrapper{
    margin-top: 12px;
  }
}