.address {
  background-color: var(--pro-secondary);
  padding: 1rem 10px;
  border-radius: 5px;
  margin: 15px 0;
}
.formFields{
  background-color: var(--pro-light);
  padding: 20px 10px 10px;
  padding-top: 1.4rem;
  border-radius: 5px;
  position: relative;
  & + .formFields{
    margin-top: 1rem;
  }
}
.corner_button{
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
  width: 12px;
  height: 12px;
  padding: 0;
  --pro-btn-close-opacity: 0.4;
  z-index: 2;
}
.display_text{
  color: gray;
}


.checkboxWrap{
  padding: 12px 0;
  &:not(:last-child){
    border-bottom: 1px solid #f7f7f7;
  }
}
