.root {
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .counter {
    position: absolute;
    bottom: 9.68px;
    color: var(--pro-light);
    right: 7.45px;
    z-index: 9;
    height: 21.69px;
    border-radius: 20px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    span {
      font-size: 12px;
      line-height: 1;
      position: relative;
      z-index: 1;
      top: -1px;
    }
    &::after {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      content: "";
      background-color: var(--pro-dark);
      opacity: 0.8;
    }
  }

  :global(.swiper-button-prev),
  :global(.swiper-button-next) {
    width: 40px;
    height: 40px;
    top: 50%;
    left: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%) rotate(135deg);
    z-index: 99;
    mix-blend-mode: difference;
    &::after {
      width: 10px;
      height: 10px;
      border-right: 2px solid var(--pro-light);
      border-bottom: 2px solid var(--pro-light);
      content: "";
    }
    &:global(.swiper-button-lock) {
      display: none;
    }
    &:global(.swiper-button-disabled) {
      opacity: 0.5;
    }
  }
  :global(.swiper-button-next) {
    left: auto;
    right: 10px;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.isPrimary {
  background-color: var(--pro-dark);
  border-radius: 30px;
  color: var(--pro-light);
  position: absolute;
  z-index: 9;
  top: 0.8333rem;
  left: 0.8333rem;
  line-height: 1.2;
}

.isRejected{
  background-color: var(--pro-danger);
  border-radius: 30px;
  color: var(--pro-light);
  position: absolute;
  z-index: 9;
  top: 0.8333rem;
  left: 0.8333rem;
  line-height: 1.2;
}

.isPremium {
  background-color: #e3aa16;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
 
}
.isInactive {
  background-color: #0a0a0a;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
  // position: absolute;
  // z-index: 9;
  // bottom: 0.8333rem;
  // left: 0.8333rem;
  // span ~ span {
  //   margin-left: 5px;
  // }
}

.isExpired {
  background-color: #0a0a0a;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
  // position: absolute;
  // z-index: 9;
  // bottom: 0.8333rem;
  // left: 0.8333rem;
  // span ~ span {
  //   margin-left: 5px;
  // }
}

.isActive {
  background-color: #3ec658;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
  // position: absolute;
  // z-index: 9;
  // bottom: 0.8333rem;
  // left: 0.8333rem;
  // span ~ span {
  //   margin-left: 5px;
  // }
}
.isPending {
  background-color: #a11d2b;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
  // position: absolute;
  // z-index: 9;
  // bottom: 0.8333rem;
  // left: 0.8333rem;
  // span ~ span {
  //   margin-left: 5px;
  // }
}
.isDeleted {
  background-color: #a11d2b;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
  // position: absolute;
  // z-index: 9;
  // bottom: 0.8333rem;
  // left: 0.8333rem;
  // span ~ span {
  //   margin-left: 5px;
  // }
}

.isBlocked {
  background-color: #a11d2b;
  border-radius: 30px;
  color: #fff;
  line-height: 1.2;
  display: flex;
  // position: absolute;
  // z-index: 9;
  // bottom: 0.8333rem;
  // left: 0.8333rem;
  // span ~ span {
  //   margin-left: 5px;
  // }
  // & + .isInactive {
  //   left: 5rem;
  // }
}


.package_container{
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  z-index: 9;
}