.root {
  cursor: pointer;
  position: relative;
  --opacity: 0;
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='48' height='48' viewBox='0,0,256,256'%0Astyle='fill:%23000000;'%3E%3Cg fill='none' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cg transform='scale(5.33333,5.33333)'%3E%3Cpath d='M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z' fill='%23ddecdd'%3E%3C/path%3E%3Cpath d='M34.586,14.586l-13.57,13.586l-5.602,-5.586l-2.828,2.828l8.434,8.414l16.395,-16.414z' fill='%234caf50'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    right: 10px;
    top: 10px;
    opacity: var(--opacity);
  }
  &.active {
    --opacity: 1;
  }
}
