.dashboardTable {
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardTable:hover {
  box-shadow: 2px 2px 10px rgb(0 0 0 / 19%);
}
.table_popup{
  position: fixed;
  top: 70px;
  left: 50%;
  z-index: 2;
  // transform: translateX(-50%);
  background-color: #fff6db;
  border-radius: 3px;
  padding: 5px 10px;
  transform: scale(0.8) translateY(-10px);
  opacity: 0;
  transition: opacity 0.25s ease, transform 0.25s ease;
  pointer-events: none;
  &.visible{
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
.aling_btn{
  transform: rotate(90deg);
}

// .user_select{
//   min-width: 150px;
//   :global(.avatar-container){
//     position: absolute;
//     top: 0;
//     max-width: 100%;
//   }
//   :global(.avatar-container .name){
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
//   :global(.pro-input .pro-input__menu){
//     z-index: 99;
//   }
// }
.color_dropdown{
  width: 100%;
  color: var(--pro-light);
  --bg-color: #0186C0;
  &::before{
    position: absolute;
    content: "";
    background-color: var(--bg-color);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  &.enquiry{
    --bg-color: #00804C;
  }
  &.broschre{
    --bg-color: #0186C0;
  }
  &.demo_arrange{
    --bg-color: #CF8900
  }
  &.follow_up{
    --bg-color: #794BD1;
  }
  &.price_factor{
    --bg-color: #000000;
  }
  &.lost{
    --bg-color: #B53030;
  }
  &.enrolled{
    --bg-color: #A80000;
  }
  &.no_prospective{
    --bg-color: #c200b8;
  }
}
.filter_btn{
  height: 100%;
  align-items: center;
}

.table{
  max-height: calc(100vh - 215px);
  overflow: auto !important;
  padding-top: 0 !important;
  margin-top: 10px;
}