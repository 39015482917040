.root{
    padding: 0.9rem 0;
    .img_wrap{
        flex-grow: 1;
        max-width: 60px;
        border-radius: 8px;
        overflow: hidden;
        & + .content{
            margin-left: 1.45rem;
        }
    }
    .id_wrap{
        li{
            display: flex;
            align-items: center;
            gap: 2px;
            >span:first-child{
                color: var(--pro-primary);
            }
            ~ li{
                margin-left: 1rem;
            }
        }
    }
    ~ .root{
        border-top: 1px solid var(--pro-outline-border);
    }
}