/* styles.css */

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.middle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  .middle_right {
    width: 33%;
    padding-right: 30px;
  }
  p {
    margin-bottom: 0;
    color: var(--pro-invoice-label);
    span {
      color: var(--pro-dark);
    }
    & + p {
      margin-top: 5px;
    }
  }
}

.left {
  flex: 1;
  p a {
    color: var(--pro-dark);
  }
  p b {
    font-size: 16px;
    line-height: 1.8;
  }
}

.right {
  background-color: #ffffff; //donot using varibale because the same color in dark mode
  height: 100%;
}

/* Table styles */
.invoiceTable {
  border-collapse: collapse;
  width: 100%;
  border: 0;
}
.footer {
  border-top: 1px dashed var(--pro-outline-border);
  padding-top: 20px;
  margin-top: 10px;
  color: var(--pro-invoice-label);
  p {
    margin-bottom: 0;
  }
  .value {
    font-size: 20px;
    color: var(--pro-dark);
  }
}

.invoiceTable th {
  background-color: var(--pro-outline-border);
}

.invoiceTable th,
.invoiceTable td {
  padding: 8px;
}

.image {
  max-width: 100%;
}

.footer_wrapper {
  border-top: 1px solid var(--pro-dark);
}
