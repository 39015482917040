.root{
    background-color: var(--pro-title-bg);
    height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--pro-light);
    color: #ffffff;
    justify-content: center;
    border-radius: 4px;
    &:not(.vertical){
        min-width: 200px;
    }
    &.vertical{
        width: 45px;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        min-height: 130px;
    }
}
.root_empty{
    background-color: var(--pro-secondary);
    border-radius: 5px;
    width: 45px;
    height: 45px;
}