.root {
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - 200px);
  padding-bottom: 10px;
  gap: 13px;
  &::-webkit-scrollbar {
    height: 7px;
  }
  > div {
    flex: none;
  }
  &_list {
    background-color: var(--pro-secondary);
    padding: 0.7rem 0.45rem;
    padding-right: 0;
    overflow: hidden;
    height: calc(100% - 50px);
    width: 100%;
    border-radius: 5px;
    & + .root_list {
      margin-left: 13px;
    }
    &_item {
      width: 100%;
      height: 100%;
      .scroll_list {
        height: 100%;
        overflow-y: auto;
        padding-right: 0.45rem;
        &::-webkit-scrollbar-track {
          background-color: var(--pro-secondary);
        }
      }

      & ~ .root_list_item {
        margin-top: 0.65rem;
      }
    }
  }
  &_item {
    width: 100%;
    max-width: 220px;
  }
  .item_head {
    background-color: #0186c0;
    color: white;
    border-radius: 3px;
    padding: 12px 10px;
    margin-bottom: 8px;
    &.enquiry {
      background-color: #000000;
    }
    &.broschre {
      background-color: #0186c0;
    }
    &.demo_arrange {
      background-color: #794bd1;
    }
    &.follow_up {
      background-color: #cf8900;
    }
    &.price_factor {
      background-color: #00804c;
    }
    &.lost {
      background-color: #b53030;
    }
    &.enrolled {
      background-color: #27a82d;
    }
    &.no_prospective {
      background-color: #c200b8;
    }
  }
  &.student_page {
    height: 500px;
    .root_item {
      max-width: 25%;
    }
  }
}
.studentCourse_board{
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  h6{
    margin-bottom: 0;
  }
}